import React from 'react'
import { Col, Row, List, Spin } from 'antd'
import { send } from 'mulan-lib'
import BlockTitle from 'lib/component/block-title'
import WenanCardItem from 'lib/component/wenan-card-item'
import LoadMore from 'lib/component/load-more'
import { processRequest, trackEvent, setTitle } from 'lib'
import Roll from './roll'
import RollShi from './roll-shi'
// import Article from './article'
import GPT from './gpt'
import ArticleV2 from './article-v2'
import Plugin from './plugin'
import Emoji from './emoji'
import MEMES from './memes'
// import Ad from './ad'
import Lunar from './lunar'
import Quan from './quan'
import './index.less'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      word: '加载中...',
      loading: true,
      from: 0,
      max: 8,
      list: [],
      hasMore: true
    }
    setTitle('文案门 · 在我的文字里，总有你的故事')
  }
  handleReset() {
    this.setState({
      loading: true,
      word: '加载中...',
      from: 0,
      max: 12,
      list: []
    })
  }
  componentDidMount() {
    setTimeout(() => {
      this.handleGetList()
      // this.handleGetUpdateCount()
    }, 3000)
  }
  handleGetList() {
    // this.handleReset()
    processRequest(
      send('/api/admin/common/func', {
        url: 'wenan/selection/list',
        data: {
          from: this.state.from,
          max: this.state.max,
        }
      }),
      data => {
        const { list } = this.state
        // const { word } = data
        this.setState({
          loading: false,
          list: list.concat(data.map(o => {return { ...o, source: 'selection' }}) || []),
        }, () => {
          // window.document.querySelector('#link-container').style.display = 'block'
        })
      }
    )
  }

  handleInfiniteOnLoad = () => {
    trackEvent('new', 'next')
    let { list, from, max, updateCount } = this.state
    this.setState({
      loading: true,
      from: from + max
    }, this.handleGetList.bind(this))
  }
  handleGetUpdateCount() {
    processRequest(
      send('/api/admin/common/func', {
        url: 'wenan/update/count',
        data: {}
      }),
      data => {

        this.setState({
          updateCount: data
        })
      }
    )
  }

  render() {
    const { loading = true, updateCount, hasMore } = this.state

    return (
      <div className={ `home ${ loading  ? 'loading' : '' }` }>
        <Row style={{ width: '100%' }} type="flex" justify="space-between" align='top'>
          <Col lg={ 16 } xs={ 24 } sm={ 24 } md={ 24 } >
            <Roll />
          </Col>
          <Col lg={ 8 } xs={ 24 } sm={ 24 } md={ 24} >
            <GPT />
            <Lunar className='home' />
          </Col>
        </Row>
        <Row type="flex" gutter={12} justify="space-between" align='top'>
          <Col lg={ 12 } xs={ 24 } sm={ 24 } md={ 24 } >
            <Emoji />
          </Col>
          <Col lg={ 12 } xs={ 24 } sm={ 24 } md={ 24} >
            <MEMES />
          </Col>
        </Row>
        <ArticleV2 />

        <Plugin />
        <Quan />

        <RollShi />

        <div className='home-content'>
          <BlockTitle title="文案尝鲜" secondary  noPadding showArrow={ false }
              extra={
                updateCount ? (
                  <div style={{
                      color: '#aaa',
                      fontWeight: 300,
                      fontSize: '.12rem',
                      fontFamily: 'Arial'
                    }}>
                    <div>近期 +{ updateCount }</div>
                  </div>
                ) : null
              } />
          <div className='home-content-list'>
            <List
              dataSource={this.state.list}

              grid={{ gutter: 12, lg:4, md: 1, sm:1, xs:1 }}
              loadMore={<LoadMore loading={ loading } onClick={ this.handleInfiniteOnLoad.bind(this) } />}
              loading={ loading }
              renderItem={item => (
                <List.Item key={item._id}>
                  <WenanCardItem
                      randomColor={ true }
                      showUserInfo={ false } wenan={ item }
                      style={{
                        height: '100%',
                        background: 'transparent'
                      }}
                      showBorder={ false } animation={ true }
                    />
                </List.Item>
              )}
            >
            </List>
          </div>
        </div>
        {this.state.loading && this.state.hasMore && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
      </div>
    )
  }
}

export default Home

// <Row style={{ width: '100%' }} type="flex" justify="space-between" align='top'>
//   <Col lg={ 14 } xs={ 24 } sm={ 24 } md={ 24 } >
//     <RollSelection />
//   </Col>
//   <Col lg={ 10 } xs={ 24 } sm={ 24 } md={ 24} >
//
//   </Col>
// </Row>
