import React from 'react'
import { Skeleton, Button } from 'antd'
import { send, moment } from 'mulan-lib'
import { browserHistory } from 'react-router'
import GAds from 'lib/component/gads'
import { processRequest, trackEvent, setTitle } from 'lib'
import { baseApiUrl } from 'config'
import './index.less'

class MEMES extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
    setTitle('迷因|梗图|memes')
  }
  componentDidMount() {
    window.document.body.scrollTo(0,0)
    this.handleGetMEME()
  }
  handleGetMEME() {
    const { from, max } = this.state
    const topCount = 0
    processRequest(
      send('/api/admin/common/func', {
        url: 'memes/rank',
        data: {
          len: 1
        }
      }),
      data => {
        const meme = data[0]
        this.setState({
          meme,
          loading: false
        }, this.handleFetchTEMP.bind(this))
        // console.log(data)
      }
    )
  }
  handleFetchTEMP() {
    const { meme } = this.state
    const { tempId } = meme
    processRequest(
      send('/api/admin/common/func', {
        url: 'memet/lite',
        data: {
          related: tempId
        }
      }),
      data => {
        const { _id } = data
        this.setState({
          memetId: _id,
        })
        // console.log(data)
      }
    )
  }
  handleReFetch() {
    window.location.href = window.location.href
    // this.setState({
    //   meme: {},
    //   loading: true
    // }, this.handleGetMEME.bind(this))
  }
  handleToTemp() {
    const { memetId } = this.state
    browserHistory.push(`/memes/new/${ memetId }`)
  }
  handleGetImg(url, href, height) {
    let styles = 'width: 100%'
     var randomId = 'img' + url
     window[`img${url}`] = `<div style="cursor: pointer; display: flex; justify-content: center;align-items: center;height: 100%; width: 100%; background-image: url(${ url });background-position: 50%;background-size: contain;background-repeat: no-repeat"><a target="_blank" href="${ href }" style="display: block; position: absolute; width: 100%; height: 100%;top:0;left: 0 "></a></div><style>body{margin:0} html{ font-size: ${ window.document.documentElement.style.fontSize } }</style><script>window.onload = function() { var el = parent.document.getElementById(\'' + randomId + '\'); el.width = 100+\'%\'; } <' + '/script>`
     var iframeStr = `<iframe id=${randomId} src="javascript:parent['img${url}']" frameBorder="0" scrolling="no" width="100%" height="100%" ></iframe>`
     return iframeStr
  }
  render() {
    const { loading = true, meme = {}, imageHeight = '5rem', memetId = '' } = this.state
    const { src = '', hot = 0, name = '' } = meme
    // <GAds slot='8345176724' />
    // <Search size='small' placeholder='请输入关键字' className='article-list-search' />
    return (
      <div className={ `memes ${ loading  ? 'loading' : '' }` }>
        <h1 className='child-page-title'>
          WE LOVE MEMES
        </h1>
        <div className='memes-content'>
          {
            !!loading ? (
              <Skeleton
                paragraph={{ rows: 12 }}
                loading={ loading }
                active />
            ) : (
              <div className='memes-content-pic'
                   dangerouslySetInnerHTML={{
                     __html: this.handleGetImg(src, '', imageHeight)
                   }} />
            )
          }
          {
          !!!loading && (<div className='memes-content-water'>文案门</div>)  
          }

        </div>
        {
          !!!loading && !!name && (
            <div className='memes-name'>
              { name }
            </div>
          )
        }

        <div className='memes-action'>
          <Button loading={ loading } type='primary' onClick={ this.handleReFetch.bind(this) } icon='reload' >换一张</Button>
          <Button onClick={ this.handleToTemp.bind(this) }>我也要制作</Button>
        </div>
        <GAds slot='8345176724' />
      </div>
    )
  }
}

export default MEMES
